import React from 'react'

import AuthManager from '../utils/AuthManager'
import Email from '../utils/Email'

import Notify from "../utils/Notify"

import { IconLinkie } from "../assets/media/svg/logo/icon/linkie"
import General from "../utils/General";

const MODE_SIGNUP = "signup"
const MODE_SIGNIN = "signin";
const MODE_FORGOT_PASSWORD = "forgot_password"
const MODE_RESET_PASSWORD = "reset_password"

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      isLoading: false,
      mode: MODE_SIGNIN,
    }
  }

  _isFormValid() {
    let { email, password, confirmPassword, mode, code } = this.state

    let error = null

    if (!Email.isValid(email)) {
      error = 'Please enter a valid email address'
    }

    if(mode === MODE_RESET_PASSWORD){
      if(!code){
        error = 'Please enter a valid code'
      }
      if(password.length < 6){
        error = 'Password must be at least 6 characters'
      }
      if(password !== confirmPassword){
        error = 'Passwords do not match'
      }
    }

    if(error){
      General.showNotification(error, false)
      return false
    }
    return true
  }

  _handleLogInClicked(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password } = this.state

    this.setState({ isLoading: true })
    AuthManager.login(email, password)
      .then(() => {
        let url = "/"
        window.location = url
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          error: error.message
        })
        General.showNotification(error.message, false);
      })
  }

  _handleRequestResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password, confirmPassword } = this.state;

    this.setState({ isLoading: true });
    AuthManager.requestResetPassword(email)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_RESET_PASSWORD,
          password: "",
          confirmPassword: "",
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
        General.showNotification(error.message, false);
      });
  }

  _handleResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password, code } = this.state;

    this.setState({ isLoading: true });
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_SIGNIN,
          email: "",
          password: "",
          confirmPassword: "",
        });
        General.showNotification("Your Password Has Been Reset")
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
        General.showNotification(error.message, false);
      });
  }

  _renderError() {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    )
  }

  _renderLogIn(){
    let {
      email,
      password,
      isLoading,
    } = this.state

    return (
      <div className="w-lg-500px p-10 p-lg-15 mx-auto">
        {/*begin::Form */}
        <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" action="#">
          {/*begin::Heading */}
          <div className="text-center mb-10">
            {/*begin::Title */}
            <h1 className="text-dark mb-3">Login To Linkie</h1>
            {/*end::Title */}
          </div>
          {/*begin::Heading */}
          {/*begin::Input group */}
          <div className="fv-row mb-10">
            {/*begin::Label */}
            <label className="form-label fs-6 fw-bolder text-dark">Email</label>
            {/*end::Label */}
            {/*begin::Input */}
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              name="email"
              autoComplete="off"
              value={email}
              autoFocus
              onChange={e => this.setState({email: e.target.value, error: null})}
            />
            {/*end::Input */}
          </div>
          {/*end::Input group */}
          {/*begin::Input group */}
          <div className="fv-row mb-10">
            {/*begin::Wrapper */}
            <div className="d-flex flex-stack mb-2">
              {/*begin::Label */}
              <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
              {/*end::Label */}
              {/*begin::Link */}
              <a
                href="javascript:void(0)"
                className="link-primary fs-6 fw-bolder"
                onClick={() => this.setState({email: "", mode: MODE_FORGOT_PASSWORD, error: null})}
              >Forgot Password ?</a>
              {/*end::Link */}
            </div>
            {/*end::Wrapper */}
            {/*begin::Input */}
            <input
              className="form-control form-control-lg form-control-solid"
              type="password"
              name="password"
              autoComplete="off"
              value={password}
              onChange={e => this.setState({password: e.target.value, error: null})}
            />
            {/*end::Input */}
          </div>
          {/*end::Input group */}
          {/*begin::Actions */}
          <div className="text-center">
            {/*begin::Submit button */}
            <button type="submit" id="kt_sign_in_submit"
                    className="btn btn-lg btn-pink w-100 mb-5"
                    onClick={e => this._handleLogInClicked(e)}
                    disabled={isLoading}
                    data-kt-indicator={isLoading ? "on" : "off"}
            >
              <span className="indicator-label">Login</span>
              <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
            {/*end::Submit button */}
          </div>
          {/*end::Actions */}
        </form>
        {/*end::Form */}
      </div>
    )
  }

  _renderForgotPassword(){
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      code,
    } = this.state

    return (
      <div className="w-lg-500px p-10 p-lg-15 mx-auto">
        <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" action="#">
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Forgotten Password?</h1>
            <p className="text-muted font-weight-bold font-size-h4">
              Enter your email to reset your password
            </p>
          </div>
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">Email</label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              name="email"
              autoComplete="off"
              value={email}
              onChange={e => this.setState({email: e.target.value, error: null})}
            />
            {/*end::Input */}
          </div>
          <div className="text-center">
            <button type="submit" id="kt_sign_in_submit"
                    className="btn btn-lg btn-pink w-100 mb-5"
                    onClick={e => this._handleRequestResetPassword(e)}
                    disabled={isLoading}
                    data-kt-indicator={isLoading ? "on" : "off"}
            >
              <span className="indicator-label">Continue</span>
              <span className="indicator-progress">Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
            <button
              className="btn btn-lg btn-light-primary w-100 mb-5"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  email: "",
                  password: "",
                  mode: MODE_SIGNIN,
                  error: null,
                });
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    )
  }

  _renderResetPassword(){
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      code,
    } = this.state

    return (
      <div className="w-lg-500px p-10 p-lg-15 mx-auto">
        <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" action="#">
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Reset Password</h1>
            <p className="text-muted font-weight-bold font-size-h4">
              A verification code was sent to your email, please enter
              it below along with your new password
            </p>
          </div>
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">Code</label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              name="code"
              autoComplete="off"
              value={code}
              onChange={e => this.setState({code: e.target.value, error: null})}
            />
            {/*end::Input */}
          </div>
          <div className="fv-row mb-10">
            {/*begin::Wrapper */}
            <div className="d-flex flex-stack mb-2">
              {/*begin::Label */}
              <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
              {/*end::Label */}
            </div>
            {/*end::Wrapper */}
            {/*begin::Input */}
            <input
              className="form-control form-control-lg form-control-solid"
              type="password"
              name="password"
              autoComplete="off"
              value={password}
              onChange={e => this.setState({password: e.target.value, error: null})}
            />
            {/*end::Input */}
          </div>
          <div className="fv-row mb-10">
            {/*begin::Wrapper */}
            <div className="d-flex flex-stack mb-2">
              {/*begin::Label */}
              <label className="form-label fw-bolder text-dark fs-6 mb-0">Confirm Password</label>
              {/*end::Label */}
            </div>
            {/*end::Wrapper */}
            {/*begin::Input */}
            <input
              className="form-control form-control-lg form-control-solid"
              type="password"
              name="password"
              autoComplete="off"
              value={confirmPassword}
              onChange={e => this.setState({confirmPassword: e.target.value, error: null})}
            />
            {/*end::Input */}
          </div>
          <div className="text-center">
            <button type="submit" id="kt_sign_in_submit"
                    className="btn btn-lg btn-pink w-100 mb-5"
                    onClick={e => this._handleResetPassword(e)}
                    disabled={isLoading}
                    data-kt-indicator={isLoading ? "on" : "off"}
            >
              <span className="indicator-label">Continue</span>
              <span className="indicator-progress">Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
            </button>
            <button
              className="btn btn-lg btn-light-primary w-100 mb-5"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  email: "",
                  password: "",
                  mode: MODE_SIGNIN,
                  error: null,
                });
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    )
  }

  render() {
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      code,
    } = this.state

    return (
      <>

        {/*begin::Main */}
        <div className="d-flex flex-column flex-root bg-body pages login">
          {/*begin::Authentication - Sign-in  */}
          <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            {/*begin::Aside */}
            <div 
              className="d-flex flex-column flex-lg-row-auto w-lg-400px w-xl-600px position-relative bg-purple overflow-hidden"
            >
              {/*begin::Wrapper */}
              <div className="d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-400px w-xl-600px flex-center">
                {/*begin::Content */}
                <div className="d-flex flex-column p-20">
                  {/*begin::Title */}
                  <h1 
                    className="fw-9 fs-3hx pb-5 text-white one-word-per-line"
                  >
                    Swipe Through Your <p style={{color: '#0bd377', margin: 0}}>Daily</p> Websites
                  </h1>
                  {/*end::Title */}
                </div>
                {/*end::Content */}
              </div>
              {/*end::Wrapper */}

              {this._renderRectangle("rectangle one", "shadow bg-pink")}
              {this._renderRectangle("rectangle two", "shadow bg-orange")}
              {this._renderRectangle("rectangle three", "shadow bg-yellow")}

            </div>
            {/*end::Aside */}
            {/*begin::Body */}
            <div className="d-flex flex-column flex-lg-row-fluid py-10">
              {/*begin::Content */}
              <div className="d-flex flex-center flex-column flex-column-fluid">
                <IconLinkie className="w-auto h-100px icon-linkie" />
                {/*begin::Wrapper */}
                { mode === MODE_SIGNIN && this._renderLogIn() }
                { mode === MODE_FORGOT_PASSWORD && this._renderForgotPassword() }
                { mode === MODE_RESET_PASSWORD && this._renderResetPassword() }
                {/*end::Wrapper */}
              </div>
              {/*end::Content */}
            </div>
            {/*end::Body */}
          </div>
          {/*end::Authentication - Sign-in */}
        </div>
        {/*end::Main */}

      </>
    )
  }

  _renderRectangle(className,color){
    return(
      <div 
        className={className}
      >
        <span
          className={color}
        />
      </div>
    )
  }

}