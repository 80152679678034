import $ from "jquery"
import FetchHelper from "./FetchHelper";

export default class General {
  static debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  static snakeCaseToTitleCase(str){
    let words = str.replace(/_/g,' ')
    return General.toTitleCase(words)
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  static getInitials(str){
    var initials = str.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials
  }

  static getRandomColorState(){
    let colors = ["info", "success", "warning", "danger", "dark", "primary", "brand"]
    let color = colors[Math.floor(Math.random()*colors.length)];
    return color
  }

  static uuid(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  static toCurrency(value){
    if(value == null){
      return 0.00
    }
    value = (value * 1)
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  static getSingleAsyncOption(value, accessor='name'){
    if (!value || typeof value !== 'object') return ''
    return {
      value: value.id,
      label: `${value[accessor]}`,
      data: value
    }
  }

  static getAsyncOptions(values, accessor='name'){
    return values.map(value => ({
      value: value.data ? value.data.id : value.id,
      label: `${value[accessor] || value.data[accessor]}`,
      data: value.data ? value.data : value
    }))
  }

  static getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  static toOrdinal(number) {
    let x = number % 10;
    let y = number % 100;

    if(x === 1 && y !== 11){
      return `${number}st`
    }
    if(x === 2 && y !== 13){
      return `${number}nd`
    }
    if(x === 3 && y !== 13){
      return `${number}rd`
    }
    return `${number}th`
  }

  static isValidVideoUrl(url){
    let validYoutubeURL = /^(https\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/.test(url);
    let validVimeoURL = /^(https\:\/\/)?(player\.vimeo\.com)\/.+$/.test(url);
    return validYoutubeURL || validVimeoURL;
  }

  static scrollTo(selector){
    $('html, body').animate({scrollTop: $(selector).position().top}, 'slow');
  }

  static showNotification(message, success = true, clickable = false) {
    let delay = 500;
    let $notificationContent = $("#notification-content");
    let $notificationContainer = $("#notification-container");
    if (success) {
      $notificationContent.addClass("success");
      $notificationContent.removeClass("error");
      if (clickable) {
        delay = 3000;
        $notificationContainer.addClass("pointer-events-unset");
      }
    } else {
      delay = 3000;
      $notificationContent.addClass("error");
      $notificationContent.removeClass("success");
      $notificationContainer.removeClass("pointer-events-unset");
    }
    $("#notification-content").text(message);
    $("#notification-container").fadeIn().delay(delay).fadeOut();
  }

}