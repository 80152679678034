export default class Url {

  static getFavIcon(url){
    if(url == null){
      return ""
    }
    const hostname = Url.extractHostname(url)
    return "http://"+hostname+"/favicon.ico"
  }

  static extractHostname(url) {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    }
    else {
      hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }
}