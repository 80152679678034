import React from "react";

export const IconLinkie = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="1311"
      height="1311"
      fill="none"
      viewBox="0 0 1311 1311"
    >
      <path
        stroke="url(#paint0_linear_445_172)"
        strokeWidth="140"
        d="M300 417.5c0-77.596 62.904-140.5 140.5-140.5S581 339.904 581 417.5v446c0 77.596-62.904 140.5-140.5 140.5S300 941.096 300 863.5v-446z"
      ></path>
      <mask
        id="mask0_445_172"
        style={{ maskType: "alpha" }}
        width="421"
        height="867"
        x="230"
        y="207"
        maskUnits="userSpaceOnUse"
      >
        <path
          stroke="url(#paint1_linear_445_172)"
          strokeWidth="140"
          d="M300 417.5c0-77.596 62.904-140.5 140.5-140.5S581 339.904 581 417.5v446c0 77.596-62.904 140.5-140.5 140.5S300 941.096 300 863.5v-446z"
        ></path>
      </mask>
      <g filter="url(#filter0_f_445_172)" mask="url(#mask0_445_172)">
        <circle
          cx="485.5"
          cy="1060.5"
          r="161.5"
          fill="#000"
          fillOpacity="0.43"
        ></circle>
      </g>
      <mask
        id="mask1_445_172"
        style={{ maskType: "alpha" }}
        width="421"
        height="867"
        x="230"
        y="207"
        maskUnits="userSpaceOnUse"
      >
        <path
          stroke="url(#paint2_linear_445_172)"
          strokeWidth="140"
          d="M300 417.5c0-77.596 62.904-140.5 140.5-140.5S581 339.904 581 417.5v446c0 77.596-62.904 140.5-140.5 140.5S300 941.096 300 863.5v-446z"
        ></path>
      </mask>
      <g filter="url(#filter1_f_445_172)" mask="url(#mask1_445_172)">
        <circle
          cx="287"
          cy="744"
          r="99"
          fill="#000"
          fillOpacity="0.65"
        ></circle>
      </g>
      <path
        stroke="url(#paint3_linear_445_172)"
        strokeWidth="140"
        d="M440.5 1004c-77.596 0-140.5-62.904-140.5-140.5S362.904 723 440.5 723h446c77.596 0 140.5 62.904 140.5 140.5S964.096 1004 886.5 1004h-446z"
      ></path>
      <mask
        id="mask2_445_172"
        style={{ maskType: "alpha" }}
        width="867"
        height="421"
        x="230"
        y="653"
        maskUnits="userSpaceOnUse"
      >
        <path
          stroke="url(#paint4_linear_445_172)"
          strokeWidth="140"
          d="M440.5 1004c-77.596 0-140.5-62.904-140.5-140.5S362.904 723 440.5 723h446c77.596 0 140.5 62.904 140.5 140.5S964.096 1004 886.5 1004h-446z"
        ></path>
      </mask>
      <g filter="url(#filter2_f_445_172)" mask="url(#mask2_445_172)">
        <circle
          cx="580.5"
          cy="739.5"
          r="134.5"
          fill="#000"
          fillOpacity="0.53"
        ></circle>
      </g>
      <path fill="url(#paint5_linear_445_172)" d="M511 644H651V794H511z"></path>
      <defs>
        <filter
          id="filter0_f_445_172"
          width="513"
          height="513"
          x="229"
          y="804"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_445_172"
            stdDeviation="47.5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter1_f_445_172"
          width="534"
          height="534"
          x="20"
          y="477"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_445_172"
            stdDeviation="84"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter2_f_445_172"
          width="605"
          height="605"
          x="278"
          y="437"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_445_172"
            stdDeviation="84"
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id="paint0_linear_445_172"
          x1="440"
          x2="440"
          y1="219.5"
          y2="1052.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE7411"></stop>
          <stop offset="0" stopColor="#FE7411"></stop>
          <stop offset="0.607" stopColor="#FF2D9A"></stop>
          <stop offset="1" stopColor="#FF2D9A"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_445_172"
          x1="440"
          x2="440"
          y1="219.5"
          y2="1052.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE7411"></stop>
          <stop offset="0" stopColor="#FE7411"></stop>
          <stop offset="0.42" stopColor="#FF2D9A"></stop>
          <stop offset="1" stopColor="#FF2D9A"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_445_172"
          x1="440"
          x2="440"
          y1="219.5"
          y2="1052.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE7411"></stop>
          <stop offset="0" stopColor="#FE7411"></stop>
          <stop offset="0" stopColor="#FF2D9A"></stop>
          <stop offset="1" stopColor="#FF2D9A"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_445_172"
          x1="226.5"
          x2="1285.5"
          y1="863"
          y2="863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2D9A"></stop>
          <stop stopColor="#FF2D9A"></stop>
          <stop offset="0.919" stopColor="#622CCC"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_445_172"
          x1="226.5"
          x2="1285.5"
          y1="863"
          y2="863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2D9A"></stop>
          <stop stopColor="#FF2D9A"></stop>
          <stop offset="0.919" stopColor="#622CCC"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_445_172"
          x1="581"
          x2="581"
          y1="644"
          y2="794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE3984"></stop>
          <stop offset="0.974" stopColor="#FF2D9A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};